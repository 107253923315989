import {
  TRANSACTION_GET_LIST,
  TRANSACTION_GET_LIST_SUCCESS,
  TRANSACTION_GET_LIST_ERROR,
  TRANSACTION_GET_LIST_WITH_FILTER,
  TRANSACTION_GET_LIST_WITH_ORDER,
  TRANSACTION_GET_LIST_SEARCH,
  TRANSACTION_ADD_ITEM,
  TRANSACTION_ADD_ITEM_SUCCESS,
  TRANSACTION_SET_BALANCE,
  TRANSACTION_ADD_ITEM_ERROR,
  TRANSACTION_UPDATE_ITEM,
  TRANSACTION_UPDATE_ITEM_SUCCESS,
  TRANSACTION_UPDATE_ITEM_ERROR,
  TRANSACTION_SELECTED_ITEMS_CHANGE,
} from '../contants';

export const getTransactionList = () => ({
  type: TRANSACTION_GET_LIST,
});

export const getTransactionListSuccess = (items) => ({
  type: TRANSACTION_GET_LIST_SUCCESS,
  payload: items,
});

export const getTransactionListError = (error) => ({
  type: TRANSACTION_GET_LIST_ERROR,
  payload: error,
});

export const getTransactionListWithFilter = (column, value) => ({
  type: TRANSACTION_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getTransactionListWithOrder = (column) => ({
  type: TRANSACTION_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getTransactionListSearch = (keyword) => ({
  type: TRANSACTION_GET_LIST_SEARCH,
  payload: keyword,
});

export const addTransactionItem = (item) => ({
  type: TRANSACTION_ADD_ITEM,
  payload: item,
});


export const addTransactionItemSuccess = (items) => ({
  type: TRANSACTION_ADD_ITEM_SUCCESS,
  payload: items,
});
export const setTransactionBalance = (item) => ({
  type: TRANSACTION_SET_BALANCE,
  payload: item,
});

export const addTransactionItemError = (error) => ({
  type: TRANSACTION_ADD_ITEM_ERROR,
  payload: error,
});
export const updateTransactionItem = (item) => ({
  type: TRANSACTION_UPDATE_ITEM,
  payload: item,
});


export const updateTransactionItemSuccess = (items) => ({
  type: TRANSACTION_UPDATE_ITEM_SUCCESS,
  payload: items,
});

export const updateTransactionItemError = (error) => ({
  type: TRANSACTION_UPDATE_ITEM_ERROR,
  payload: error,
});

export const selectedTransactionItemsChange = (selectedItems) => ({
  type: TRANSACTION_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});
