import {gql} from "@apollo/client";

export const LOAD_USERS = gql`
   query{
       getAllUsers{
           id
           name
           password
       }
   }
`;
export const LOAD_BANKS = gql`
   query{
     banks{
           value
           key
       }
   }
`;

export const LOAD_USER_PAYMENT_METHODS = gql`
query getUserPaymentMethods {
    paymentMethods {
    
      id
      userId
      accountName
      accountNumber
      bankName
      code
      method
  
    }
  }
`;

export const GET_ALL_DELIVERIES = gql`

    query getAllDeliveries {
        deliveries {
            id
            receiverName
            receiverPhone
            pickup
            token
            status
            destination
            pickupPlaceId
            destinationPlaceId
            pickupLat
            pickupLong
            destinationLat
            destinationLong
            cost
            remarks
            totalCost
            quantity
            itemType
            vehicleTypeId
            distance
            duration
            createdAt
            deliveryStatuses{
                id
                status
                createdBy
                createdAt
            }
        }
    }
`
// export const GET_ALL_DELIVERIES = gql`
//     query {
//         deliveries {
//             receiverName
//             receiverPhone
//             pickup
//             token
//             status
//             destination
//             pickupPlaceId
//             destinationPlaceId
//             pickupLat
//             pickupLong
//             destinationLat
//             destinationLong
//             cost
//             remarks
//             totalCost
//             quantity
//             itemType
//             vehicleTypeId
//             distance
//             duration
//             deliveryStatuses{
//                 id
//                 status
//                 createdBy
//             }
//         }
//     }
 
// `;

export const GET_DELIVERY = gql`
query getDelivery($token:String!){
    delivery(token:$token){
        receiverName
        receiverPhone
        pickup
        token
        status
        destination
        pickupPlaceId
        destinationPlaceId
        pickupLat
        pickupLong
        destinationLat
        destinationLong
        cost
        remarks
        totalCost
        quantity
        itemType
        vehicleTypeId
        distance
        duration
        createdAt
        deliveryStatuses{
            id
            status
            createdBy
            createdAt
        }
    }
}
`;
export const GET_VEHICLE_TYPES = gql`
query VehicleTypes {
  vehicleTypes {
    id
    type
    basePrice
    baseDistance
    constant
    pricePerKilometer
    pricePerMinute
  }
}

`;
export const GET_USER_WALLET = gql`
query getUserWallet {
  userWallet {
    id
    amount
    balance
  }
}`;

export const GET_USER_WALLETS = gql`
query getUserWallets {
  userWallets {
    id
    amount
    balance
    reference
    status
    timeOfTransaction
  }
}

`;
export const LOAD_USER_PAYMENTS = gql`
query getUserPayments{
  userPayments {
    id
    amount
    method
    status
    reference
    createdAt
  }
}
`;

export const GET_DELIVERY_REQUEST = gql`
query getDeliveryRequest($phone:String,$token:String){
  deliveryRequest(phone:$phone,token:$token) {
    name
    senderPhone
    receiverPhone
    details
    product
    status
    escrow
    isSurprise
    createdAt
  }
}

`;