
import { split } from 'apollo-link';
import {WebSocketLink} from 'apollo-link-ws';
import {getMainDefinition} from 'apollo-utilities';
import {
  ApolloClient,
  HttpLink,
  from,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { graphqlUrl, subscriptionUrl } from '../config';
import { getCurrentUser } from '../helpers/Utils';


const errorLink = onError(({ graphqlErrors, networkError }) => {
  console.log('network errors', networkError)
  if (graphqlErrors) {
    console.log('Graphql errors ',graphqlErrors)
    graphqlErrors.map(({ message, location, path }) => {
      console.log(`Graphql error message ${message}`)
      console.log(`Graphql error location ${location}`)
      console.log(`Graphql error path ${path}`)
    })
  }
})
let token = getCurrentUser()?.uid??"";
// console.log(token)
// const ip = ''
// getAuthToken().then(res=>{
//   token = res
// }); 
const httpLink = from([
  errorLink,
  new HttpLink({ uri: graphqlUrl}),
]);
const wsLink = new WebSocketLink({
  uri: subscriptionUrl,
  options: {
    reconnect: true
  }
});
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: token ,
    //   authorization: "",
    }
  }
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);
const apolloClientInstance = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink ,
})

export default apolloClientInstance