import {
  TODO_GET_LIST,
  TODO_GET_LIST_SUCCESS,
  TODO_GET_LIST_ERROR,
  TODO_GET_LIST_WITH_FILTER,
  TODO_GET_LIST_WITH_ORDER,
  TODO_GET_LIST_SEARCH,
  TODO_ADD_ITEM,
  TODO_SAVE_ITEM,
  TODO_SAVE_ITEM_SUCCESS,
  TODO_SAVE_ITEM_ERROR,
  GET_USER_LOCATION,
  SET_USER_LOCATION,
  TODO_ADD_ITEM_SUCCESS,
  TODO_ADD_ITEM_ERROR,
  TODO_SELECTED_ITEMS_CHANGE,
} from '../contants';

const INIT_STATE = {
  allTodoItems: [],
  todoItems: [],
  userLocation: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loaded: false,
  labels: [
    { label: 'EDUCATION', color: 'secondary' },
    { label: 'NEW FRAMEWORK', color: 'primary' },
    { label: 'PERSONAL', color: 'info' },
  ],
  orderColumns: [
    { column: 'title', label: 'Title' },
    { column: 'category', label: 'Category' },
    { column: 'status', label: 'Status' },
    { column: 'label', label: 'Label' },
  ],
  categories: ['Yes', 'No'],
  selectedItems: [],
};

const todoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TODO_GET_LIST:
      return { ...state, loaded: false };

    case GET_USER_LOCATION:
      return { ...state, loaded: false };

    case TODO_GET_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        allTodoItems: action.payload,
        todoItems: action.payload,
      };

    case TODO_GET_LIST_ERROR:
      return { ...state, loaded: true, error: action.payload };

    case TODO_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          loaded: true,
          todoItems: state.allTodoItems,
          filter: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const filteredItems = state.allTodoItems.filter(
        (item) => item[action.payload.column] === action.payload.value
      );
      return {
        ...state,
        loaded: true,
        todoItems: filteredItems,
        filter: {
          column: action.payload.column,
          value: action.payload.value,
        },
      };

    case TODO_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return {
          ...state,
          loaded: true,
          todoItems: state.todoItems,
          orderColumn: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const sortedItems = state.todoItems.sort((a, b) => {
        if (a[action.payload] < b[action.payload]) return -1;
        if (a[action.payload] > b[action.payload]) return 1;
        return 0;
      });
      return {
        ...state,
        loaded: true,
        todoItems: sortedItems,
        orderColumn: state.orderColumns.find(
          (x) => x.column === action.payload
        ),
      };

    case TODO_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, todoItems: state.allTodoItems };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchItems = state.allTodoItems.filter(
        (item) =>
          item.title.toLowerCase().indexOf(keyword) > -1 ||
          item.detail.toLowerCase().indexOf(keyword) > -1 ||
          item.status.toLowerCase().indexOf(keyword) > -1 ||
          item.category.toLowerCase().indexOf(keyword) > -1 ||
          item.label.toLowerCase().indexOf(keyword) > -1
      );
      return {
        ...state,
        loaded: true,
        todoItems: searchItems,
        searchKeyword: action.payload,
      };

    case TODO_ADD_ITEM:
      return { ...state, loaded: false };

    case SET_USER_LOCATION:
      return { ...state,userLocation:action.payload, loaded: true };

    // case TODO_ADD_ITEM_SUCCESS:
    //   return {
    //     ...state,
    //     loaded: true,
    //     // allTodoItems: action.payload,
    //     // todoItems: action.payload,
    //   };

    case TODO_ADD_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };


    case TODO_SAVE_ITEM:
      return { ...state, loaded: false };

    case TODO_SAVE_ITEM_SUCCESS:
      return {
        ...state,
        loaded: true,
        allTodoItems:[...state.todoItems,action.payload],
        todoItems: [...state.todoItems,action.payload],
      };

    case TODO_SAVE_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };




    case TODO_SELECTED_ITEMS_CHANGE:
      return { ...state, loaded: true, selectedItems: action.payload };
    default:
      return { ...state };
  }
};
export default todoReducer