import {gql} from "@apollo/client";


export const CREATE_ESCROW_MUTATION  = gql`
mutation addEscrow ($amount:Float,$type:Int,$reference:String) {
  createEscrow(input: {amount:$amount,type:$type,reference:$reference}) {
    status
    statusCode
    message
    data {
      amount
      type
    }
  }
}
`;
export const MAKE_TRANSFER_MUTATION  = gql`
mutation createTransferInfo ($amount:Float,$methodId:String,$reference:String) {
   createTransfer(input: {amount:$amount,methodId:$methodId,reference:$reference}) {
    status
    statusCode
    message
    data {
      reference
    }
  }
}
`;
export const MAKE_PAYMENT_MUTATION = gql`
mutation createPaymentInfo($amount:Float,$method:String,$reference:String,$status:String) {
  createPayment(input: {amount:$amount,method:$method,reference:$reference,status:$status}) {
    status
    statusCode
    message
    data {
      reference
      authURL
      amount
      status
    }
  }
}
`;
export const CREATE_USER_MUTATION = gql`
   mutation signUpUser($name:String!,$phone:String!,$email:String!,$password:String!,$companyName:String,$companyRegNumber:String,$companyAddress:String,$companyTIN:String,$isCompany:Boolean){
    signUp(input:{
      name:$name,phone:$phone,email:$email,password:$password,companyName:$companyName,companyRegNumber:$companyRegNumber,companyAddress:$companyAddress,companyTIN:$companyTIN,isCompany:$isCompany
    }){
      token
      user{
        id
        name
        phone
        roles
      }
    }
  }
`;
export const CREATE_AWARENESS_MUTATION = gql`
mutation ReceipientAcceptDelivery($name: String, $senderPhone: String, $details: String, $receiverPhone: String, $status: String, $product: String,$escrow:Float,$isSurprise:Boolean) {
  receipientAcceptDelivery(name: $name, senderPhone: $senderPhone, details: $details, receiverPhone: $receiverPhone, status: $status, product: $product,escrow:$escrow,isSurprise:$isSurprise) {
    status
    statusCode
    message
  }
}
`;

export const LOGIN_MUTATION = gql`
mutation signInUser($email:String!,$password:String!){
    signIn(input:{
      email:$email,password:$password
    }){
      token
      user{
        id
        name
        phone
      }
    }
  }
`;
export const CHANGE_PASSWORD_MUTATION = gql`
mutation changeUserPassword($phone:String!,$password:String!){
  resetPassword(input:{
      phone:$phone,password:$password
    }){
      authUser{
        token
      user{
        id
        name
        phone
      }
      }
    }
  }
`;
export const CREATE_DLIVERY_MUTATION = gql`
mutation createDeliveryInfo(
  $receiverName:String!,
  $receiverPhone:String,
  $secret:String,
  $zone:String,
  $pickup:String,
  $token:String,
  $product:String,
  $toEscrow:Boolean,
  $isSurprise:Boolean,
  $escrowValue:Float,
  $status:String,
  $destination:String,
  $pickupPlaceId:String,
  $destinationPlaceId:String,
  $pickupLat:String,
  $pickupLong:String,
  $destinationLat:String,
  $destinationLong:String,
  $cost:String,
  $remarks:String,
  $totalCost:String,
  $quantity:Int,
  $itemType:String,
  $vehicleTypeId:String,
  $distance:String,
  $duration:String,
  $weight:String,
  $vat:String,
  $priceValue:Float,
){
createDelivery(
 input:{
  receiverName:$receiverName,
  receiverPhone:$receiverPhone,
  secret:$secret,
  zone:$zone,
  pickup:$pickup,
  token:$token,
  isSurprise:$isSurprise,
  destination:$destination,
  pickupPlaceId:$pickupPlaceId,
  destinationPlaceId:$destinationPlaceId,
  pickupLat:$pickupLat,
  pickupLong:$pickupLong,
  destinationLat:$destinationLat,
  destinationLong:$destinationLong,
  cost:$cost,
  status:$status,
  product:$product,
  toEscrow:$toEscrow,
  escrowValue:$escrowValue,
  remarks:$remarks,
  totalCost:$totalCost,
  quantity:$quantity,
  itemType:$itemType,
  vehicleTypeId:$vehicleTypeId,
  duration:$duration,
  distance:$distance,
  weight:$weight,
  vat:$vat,
  priceValue:$priceValue,
  }
){
  id
  receiverName
  receiverPhone
  pickup
  token
  status
  destination
  product
  pickupPlaceId
  destinationPlaceId
  pickupLat
  pickupLong
  destinationLat
  destinationLong
  cost
  remarks
  totalCost
  quantity
  itemType
  vehicleTypeId
  distance
  duration
  createdAt
  deliveryStatuses{
      id
      status
      createdBy
      createdAt
  }
}
}
`;

export const CREATE_PAYMENT_METHOD_MUTATION = gql`
mutation createPaymentMethod($accountName:String,$accountNumber:String,$code:String,$bankName:String,$method:String) {
  createPaymentMethod(input:{accountName: $accountName,accountNumber:$accountNumber,code:$code,bankName:$bankName,method:$method}) {
    status
    statusCode
    message
    data {
      id
      accountName
      accountNumber
      code
      bankName
      method
    }
  }
}
`;
export const SEND_OTP_MUTATION = gql`
mutation sendUserOTP($number:String) {
  sendOTP(input:{number: $number}) {
    status
    statusCode
    message
  }
}
`;