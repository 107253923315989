import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getCurrentUser, getDateWithFormat } from '../../helpers/Utils';

import transactionData from '../../data/Transaction.json';
import { TRANSACTION_GET_LIST, TRANSACTION_ADD_ITEM,TRANSACTION_UPDATE_ITEM } from '../contants';

import {
  getTransactionListSuccess,
  getTransactionListError,
  addTransactionItemSuccess,
  addTransactionItemError,
  updateTransactionItemSuccess,
  updateTransactionItemError,
} from './actions';
import { GET_USER_WALLETS,  } from '../../GraphQL/Queries'
import { MAKE_PAYMENT_MUTATION } from '../../GraphQL/Mutations'
import apolloClientInstance from '../../GraphQL/apolloClient'
import { subscribeVerify } from '../../services/transactionService';


const getTransactionListRequest = async () => {
  // eslint-disable-next-line no-return-await
  const result = await apolloClientInstance.query({
    query: GET_USER_WALLETS,
    variables:{phone: ""}
  }).then(res=> res).catch(err=>console.log(err))
  

  return result.data.userWallets;
};

function* getTransactionListItems() {
  try {
    const response = yield call(getTransactionListRequest);
    yield put(getTransactionListSuccess(response));
  } catch (error) {
    yield put(getTransactionListError(error));
  }
}

const addTransactionItemRequest = async (item) => {
/////////////////////////////////////////////////////////
 console.log('adding item',item)

  const result = await apolloClientInstance.mutate({
    mutation: MAKE_PAYMENT_MUTATION,
    variables:{
      amount: parseFloat(item),
      reference: `ch-${Date.now()}${item}`,
      method: "gf",
      status: "init"
      // name:getCurrentUser().title,senderPhone:getCurrentUser().phone,product:item.detail,details:item.product,receiverPhone:item.title,status:"init",escrow:0,isSurprise:false 
    }
  })
  return {...result.data.createPayment.data,amount:item};

////////////////////////////////////////////////////////////
  

};

function* addTransactionItem({ payload }) {
  try {
    const response = yield call(addTransactionItemRequest, payload);
    yield put(addTransactionItemSuccess(response));
  } catch (error) {
    yield put(addTransactionItemError(error));
  }
}
const updateTransactionItemRequest = async (item) => {
/////////////////////////////////////////////////////////

const res = await subscribeVerify(item.reference)
console.log(' info',item)
const returnedInfo = JSON.parse(res)
if(!returnedInfo.status){

}
else if (returnedInfo.data.status == 'abandoned') {
    return;
}
////////// Creating actual payment and crediting wallet //////////
if (returnedInfo.status && returnedInfo.data.status == 'success') {
  
    const result = await apolloClientInstance.mutate({
      mutation: MAKE_PAYMENT_MUTATION,
      variables:{
        amount: parseFloat(item.amount),
            reference: returnedInfo.data.reference,
            method: 'gf',
            status: returnedInfo.data.status ? 'verified' : returnedInfo.data.status
        // name:getCurrentUser().title,senderPhone:getCurrentUser().phone,product:item.detail,details:item.product,receiverPhone:item.title,status:"init",escrow:0,isSurprise:false 
      }
    })
    console.log('ret',result.data.createPayment.data)
    return result.data.createPayment.data;
  
 
}

////////////////////////////////////////////////////////////
  

};

function* updateTransactionItem({ payload }) {
  try {
    const response = yield call(updateTransactionItemRequest, payload);
    yield put(updateTransactionItemSuccess(response));
  } catch (error) {
    yield put(updateTransactionItemError(error));
  }
}


export function* watchGetList() {
  yield takeEvery(TRANSACTION_GET_LIST, getTransactionListItems);
}

export function* wathcAddItem() {
  yield takeEvery(TRANSACTION_ADD_ITEM, addTransactionItem);
}
export function* wathcUpdateItem() {
  yield takeEvery(TRANSACTION_UPDATE_ITEM, updateTransactionItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcUpdateItem)]);
}
