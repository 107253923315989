import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store  from './redux/store';
import reportWebVitals from './reportWebVitals';
import {
  ApolloProvider,

} from '@apollo/client';
import apolloClientInstance from '../src/GraphQL/apolloClient'




const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

// const Main = () => {
//   return (
//     <Provider store={configureStore()}>
//       <Suspense fallback={<div className="loading" />}>
//         <App />
//       </Suspense>
//     </Provider>
//   );
// };

// ReactDOM.render(<Main />, document.getElementById('root'));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
  <Suspense fallback={<div className="loading" />}>
  <ApolloProvider client={apolloClientInstance} >
    <App />
    </ApolloProvider>
  </Suspense>
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
