import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getCurrentUser, getDateWithFormat } from '../../helpers/Utils';

import todoData from '../../data/todos.json';
import { TODO_GET_LIST, TODO_ADD_ITEM ,TODO_SAVE_ITEM} from '../contants';

import {
  getTodoListSuccess,
  getTodoListError,
  addTodoItemSuccess,
  addTodoItemError,
  saveTodoItemSuccess,
  saveTodoItemError,
} from './actions';
import { GET_ALL_DELIVERIES } from '../../GraphQL/Queries'
import { CREATE_AWARENESS_MUTATION } from '../../GraphQL/Mutations'
import apolloClientInstance from '../../GraphQL/apolloClient'


const getTodoListRequest = async () => {
  // eslint-disable-next-line no-return-await
  const result = await apolloClientInstance.query({
    query: GET_ALL_DELIVERIES,
    variables:{phone: ""}
  }).then(res=>res).catch(err=>console.log(err))

 return result.data.deliveries;
};

function* getTodoListItems() {
  try {
    const response = yield call(getTodoListRequest);
    yield put(getTodoListSuccess(response));
  } catch (error) {
    yield put(getTodoListError(error));
  }
}

const addTodoItemRequest = async (item) => {
/////////////////////////////////////////////////////////


  const result = await apolloClientInstance.mutate({
    mutation: CREATE_AWARENESS_MUTATION,
    variables:{name:getCurrentUser().title,senderPhone:getCurrentUser().phone,product:item.detail,details:item.product,receiverPhone:item.title,status:"init",escrow:0,isSurprise:false }
  })
  console.log('CREATE_AWARENESS_MUTATION',result.data)

////////////////////////////////////////////////////////////
  
 
};

function* addTodoItem({ payload }) {
  try {
    const response = yield call(addTodoItemRequest, payload);
    yield put(addTodoItemSuccess(response));
  } catch (error) {
    yield put(addTodoItemError(error));
  }
}

function* saveTodoItem({ payload }) {
  try {
    yield put(saveTodoItemSuccess(payload));
  } catch (error) {
    yield put(saveTodoItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(TODO_GET_LIST, getTodoListItems);
}
export function* watchSaveTodo() {
  yield takeEvery(TODO_SAVE_ITEM, saveTodoItem);
}

export function* wathcAddItem() {
  yield takeEvery(TODO_ADD_ITEM, addTodoItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem),fork(watchSaveTodo)]);
}
