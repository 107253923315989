import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

// eslint-disable-next-line import/prefer-default-export
const  store = configureStore(
  {
    reducer,
    middleware:()=>middlewares
  }
);
sagaMiddleware.run(sagas)
export default store;
