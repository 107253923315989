export const subscribeVerify = async (reference) =>{
    let requestOptions = {
        method: 'GET',
        headers: { "Authorization": "Bearer sk_test_2b18ef53aaf5cb6d526ad76ce24f4448c22fa626", "Content-Type": "application/json" },
        redirect: 'follow'
    };
    let response = await fetch(`https://api.paystack.co/transaction/verify/${reference}`, requestOptions);
    console.log(response)
  let period = 10000;
    if (response.status == 502) {
      // Status 502 is a connection timeout error,
      // may happen when the connection was pending for too long,
      // and the remote server or a proxy closed it
      // let's reconnect
      await subscribeVerify(requestOptions,period);
    } 
    // else if (response.status != 200) {
    //   // An error - let's show it
    //   Alert.alert(response.status);
    //   // Reconnect in one second
    //   await new Promise(resolve => setTimeout(resolve, period));
    //   period = period +1000
    //   await subscribeVerify(requestOptions,period);
    // } 
    else {
      // Get and show the message
      return await response.text();
      // Call subscribeVerify() again to get the next message
    //   await subscribeVerify(requestOptions,period);
    }
  }
