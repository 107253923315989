import {
  TRANSACTION_GET_LIST,
  TRANSACTION_GET_LIST_SUCCESS,
  TRANSACTION_GET_LIST_ERROR,
  TRANSACTION_GET_LIST_WITH_FILTER,
  TRANSACTION_GET_LIST_WITH_ORDER,
  TRANSACTION_GET_LIST_SEARCH,
  TRANSACTION_ADD_ITEM,
  TRANSACTION_ADD_ITEM_SUCCESS,
  TRANSACTION_SET_BALANCE,
  TRANSACTION_ADD_ITEM_ERROR,
  TRANSACTION_UPDATE_ITEM,
  TRANSACTION_UPDATE_ITEM_SUCCESS,
  TRANSACTION_UPDATE_ITEM_ERROR,
  TRANSACTION_SELECTED_ITEMS_CHANGE,
} from '../contants';

const INIT_STATE = {
  allTransactionItems: [],
  transactionItems: [],
  transactionAmount: 0,
  balance: 0,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loaded: false,
  orderColumns:[],
  selectedItems:[],

};

const transactionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TRANSACTION_GET_LIST:
      return { ...state, loaded: false };

 
    case TRANSACTION_GET_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        allTransactionItems: action.payload,
        transactionItems: action.payload,
      };

    case TRANSACTION_GET_LIST_ERROR:
      return { ...state, loaded: true, error: action.payload };

    case TRANSACTION_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          loaded: true,
          transactionItems: state.allTransactionItems,
          filter: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const filteredItems = state.allTransactionItems.filter(
        (item) => item[action.payload.column] === action.payload.value
      );
      return {
        ...state,
        loaded: true,
        transactionItems: filteredItems,
        filter: {
          column: action.payload.column,
          value: action.payload.value,
        },
      };

    case TRANSACTION_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return {
          ...state,
          loaded: true,
          transactionItems: state.transactionItems,
          orderColumn: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const sortedItems = state.transactionItems.sort((a, b) => {
        if (a[action.payload] < b[action.payload]) return -1;
        if (a[action.payload] > b[action.payload]) return 1;
        return 0;
      });
      return {
        ...state,
        loaded: true,
        transactionItems: sortedItems,
        orderColumn: state.orderColumns.find(
          (x) => x.column === action.payload
        ),
      };

    case TRANSACTION_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, transactionItems: state.allTransactionItems };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchItems = state.allTransactionItems.filter(
        (item) =>
          item.title.toLowerCase().indexOf(keyword) > -1 ||
          item.detail.toLowerCase().indexOf(keyword) > -1 ||
          item.status.toLowerCase().indexOf(keyword) > -1 ||
          item.category.toLowerCase().indexOf(keyword) > -1 ||
          item.label.toLowerCase().indexOf(keyword) > -1
      );
      return {
        ...state,
        loaded: true,
        transactionItems: searchItems,
        searchKeyword: action.payload,
      };

    case TRANSACTION_ADD_ITEM:
      return { ...state, loaded: false };

 
    case TRANSACTION_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loaded: true,
        allTransactionItems: [action.payload,...state.allTransactionItems],
        transactionItems:[action.payload,...state.transactionItems]
      };
    case TRANSACTION_SET_BALANCE:
      return {
        ...state,
        loaded: true,
        balance:action.payload
      };

    case TRANSACTION_ADD_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };

    case TRANSACTION_UPDATE_ITEM:
      return { ...state, loaded: false };

 
    case TRANSACTION_UPDATE_ITEM_SUCCESS:{
      const index = state.transactionItems.findIndex(obj=>obj.reference ===action.payload.reference)
      state.transactionItems[index].status = action.payload.status
      return {
        ...state,
        loaded: true,
        allTransactionItems: [...state.allTransactionItems,action.payload],
        // transactionItems:[...state.transactionItems,action.payload],
      };
    }
      

    case TRANSACTION_UPDATE_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };

    case TRANSACTION_SELECTED_ITEMS_CHANGE:
      return { ...state, loaded: true, selectedItems: action.payload };
    default:
      return { ...state };
  }
};

export default transactionReducer