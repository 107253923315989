// export const base = "://192.168.43.211:4000" //pacifik
export const baseLive = "://api.chumiie.com" //Live

// export const base = "://192.168.8.104:4000" //pacifikb mifi
// export const base = "://192.168.100.109:4000"//Gyandu 
// export const base = "://192.168.100.17:4000"//Jayke

export const base = "://192.168.100.3:4000"//Jayke

// export const base = "://192.168.100.103:4000" //GyanduKobe

export const baseUrl = "http" + base
export const baseUrlLive = "https" + baseLive

export const graphqlUrl = baseUrlLive + "/graphql"
// export const subscriptionUrl = "ws" + baseLive + "/subscriptions"//insecure
export const subscriptionUrl = "wss" + baseLive + "/subscriptions"

// export const API_KEY = "AIzaSyDMn1clOcSpDyF50KXO2t8Sb3qdaOLzVF0" //Pacifik

export const API_KEY = "AIzaSyDzJM9QTkfIf1psuXht4lGCi73ZY0nL-gg" // Speakupp
